import React, { useEffect, useRef, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import BlueButton from "../Components/BlueButton";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { updateCartCount } from "../redux/ActionCreator";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { load } from "@cashfreepayments/cashfree-js";

const CourseTerms = () => {
  const navigate = useNavigate();
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const [cartData, setCartData] = useState([]);
  const [tnc, setTnc] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const dispatch = useDispatch();
  const loading = useRef(null);
  const [isPopUp, setIsPopUp] = useState(false);
  const popUp = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/tnc`);
        setTnc(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/cart/get-cart`, {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
        });
        setTotalAmount(res.data.data.totalAmount);
        setCartData(res.data.data.cart);
        dispatch(updateCartCount(res.data.data.cart.length));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handlePopUp = (e) => {
    e.preventDefault();
    setIsPopUp(true);
    popUp.current?.scrollIntoView({
      behaviour: "smooth",
    });
  };

  const initiatePayment = async () => {
    setIsPopUp(false);
    loading.current?.scrollIntoView({ behavior: "smooth" });
    setIsPaymentProcessing(true);

    if (cartData.length === 0) {
      setIsPaymentProcessing(false);
      toast.error("Add at least one course to cart");
      return;
    }

    if (!profileDetails.token) {
      navigate("/login");
      return;
    }

    const params = {
      amount: JSON.stringify(totalAmount),
      id: profileDetails.id,
      customer_name: profileDetails.full_name,
      customer_mobile: profileDetails.phone,
      customer_email: profileDetails.email,
      currency: "INR",
      sms_notify: false,
      email_notify: true,
      partial_payment: true,
      additional_field1: "",
      additional_field2: "",
      redirect_url: `${Endpoints.SITE_URL}/my-courses`,
    };

    try {
      const res = await axios.post(
        `${Endpoints.API_URL}hylo/payment-page`,
        params,
        {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
        }
      );

      setIsPaymentProcessing(false);

      if (res.data.status) {
        const cashFreeSession = res.data.data;

        const cashfree = await load({ mode: "production" });
        cashfree.checkout({
          paymentSessionId: cashFreeSession,
          redirectTarget: "_self",
        });
      } else {
        console.log(res.data);
      }
    } catch (error) {
      setIsPaymentProcessing(false);
      console.error("Payment initiation error:", error);
      toast.error("Error initiating payment. Please try again.");
    }
  };

  return (
    <LoadingOverlay
      active={isPaymentProcessing}
      spinner
      text="Please wait... Processing"
    >
      <div>
        <div className=""></div>
        <PageBanner imgUrl="/assets/Governing-board-banner.png" />
        <section className="container max-sm:px-4 mx-auto">
          <div>
            <h1 className="flex my-20 font-semibold max-sm:text-[20px] text-gray-500 uppercase mb-4 items-center text-[24px]  gap-4 ">
              <FiArrowLeft onClick={() => navigate(-1)} /> Terms & Conditions
            </h1>
          </div>
          <div className="border rounded-lg border-gray-300 ">
            <div
              className="content p-2"
              dangerouslySetInnerHTML={{ __html: tnc?.tnc }}
            ></div>

            <form onSubmit={handlePopUp}>
              <div className="flex justify-end">
                <div className="flex justify-end flex-col gap-6 p-6  font-semibold">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      className="h-[20px] w-[20px]"
                      required
                    />
                    <span>Yes,I am eligible for this course .</span>
                  </div>
                  <div className="flex items-center gap-3  font-semibold">
                    <input
                      type="checkbox"
                      className="h-[20px] w-[20px]"
                      required
                    />
                    <span>I have read & agree to the terms of service.</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-end gap-10 max-sm:flex-col max-sm:text-[12px] my-14 mb-20">
            <BlueButton onClick={handlePopUp}>Proceed to Payment</BlueButton>
          </div>
        </section>
        {/* alert pop-up */}
        <div
          className={`${
            isPopUp ? "flex" : "hidden"
          } fixed  justify-center items-center top-0 left-0 right-0 bottom-0 bg-opacity-50  bg-black w-full`}
        >
          <div className="flex flex-col justify-between  bg-[#f1f3f4] rounded-md">
            <div className="flex flex-col justify-center items-center flex-1 md:flex-row gap-10 p-10">
              <div className="max-w-[200px] max-h-[200px]">
                <img
                  src="/assets/payment-alert.png"
                  alt="alert"
                  className="w-full h-auto"
                />
              </div>
              <div className="flex flex-col flex-1 justify-center gap-4 items-center text-center font-bold">
                <p>
                  <p className="inline text-red-600">IMPORTANT.</p> You are
                  about to make the payment.
                </p>
                <p>
                  Please do not press{" "}
                  <p className="inline text-red-600">Back Button</p> or{" "}
                  <p className="inline text-red-600">Refresh</p>.
                </p>
                <p>
                  Please do not close the{" "}
                  <p className="inline text-red-600">Browser</p> or{" "}
                  <p className="inline text-red-600">Tab</p>.
                </p>
                <p>
                  Wait for the transactions to fully complete after you perform
                  the payment.
                </p>
              </div>
            </div>
            <div className="bg-white rounded-b-md flex justify-end gap-4 p-4">
              <button
                className="bg-gray-400 py-3 px-5 font-bold rounded-xl mx-3"
                onClick={() => setIsPopUp(false)}
              >
                Cancel
              </button>
              <BlueButton onClick={initiatePayment}>
                Proceed to Payment
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default CourseTerms;
