// export function processData(data) {
//   const result = [];
//   for (const [key, value] of Object.entries(data)) {

//     result.push({ name: key, data: value });
//   }
//   return result;
// }

export function processData(data) {
  // Flatten the data into an array
  const coursesArray = Object.values(data).flat();

  // Sort the array by category.order_category
  const sortedCourses = coursesArray.sort((a, b) => a.category.order_category - b.category.order_category);

  // Group the sorted courses by their category
  const groupedCourses = sortedCourses.reduce((acc, course) => {
    const categoryName = course.category.name;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(course);
    return acc;
  }, {});

  // Convert the grouped object back into an array
  const result = Object.entries(groupedCourses).map(([key, value]) => ({ name: key, data: value }));
  
  return result;
}


export function generateUniqueNumber() {
  const min = 1000000000; // Minimum value
  const max = 9999999999; // Maximum value
  const uniqueNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return uniqueNumber;
}
