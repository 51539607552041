export class Endpoints {
  static BASE_URL = "https://api.eurotechmaritime.org";
  static API_URL = "https://api.eurotechmaritime.org/api/";
  static CMS_URL = "https://api.eurotechmaritime.org/api/cms/";
  static SITE_URL= "https://eurotechmaritime.org";
}

// export class Endpoints {
//   static BASE_URL = "http://localhost:3002";
//   static API_URL = "http://localhost:3002/api/";
//   static CMS_URL = "http://localhost:3002/api/cms/";
//   static SITE_URL = "http://localhost:3000";
// }
// https://api.cashfree.com/pg/sdk/js/pay
