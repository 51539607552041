// OLD LIST
export const data0 = [
    {
        title: "Head of the Institute",
        name: "C/E NATARAJAN VENKATESHWARAN (VKT)",
        qualification: "MEO CLASS I"
    }
];

export const data1 = [
  {
    name: "MR AJIT NARAYAN PILLAI (AJN)",
    qualification: "CHIEF OFFICER",
  },
  {
    name: "'C/E ALBERT ANTONY PULIPPARAMBIL(ABT)",
    qualification: "CHIEF OFFICER",
  },
  {
    name: "MS ANNA BOBAN (ABP)",
    qualification: "MSC (FOOD SC & NUTRITION)",
  },
  {
    name: "C/E BASKARAN HARiDOS (BAS)",
    qualification: "CHIEF OFFICER",
  },
  {
    name: "CAPT.BIJU VARGHESE THEETHAY I(BJV)",
    qualification: "MASTER MARINER (FG)",
  },
  {
    name: "MR GIJU GEORGE (GJG)",
    qualification: "ETO",
  },
  {
    name: "CAPT JOJY KUNJATTIL (JJK)\nCAPT K M DILEEP (KMD)",
    qualification: "MASTER MARINER(FG)\nMASTER MARINER(FG)",
  },
  {
    name: "MR. MATHESH BABU",
    qualification: "NAVAL ARCHITECT",
  },
  {
    name: "MS MEERA PANICKER (MER)",
    qualification: "MTECH",
  },
  {
    name: "CAPT MURALIDHAR JITIN (MUJ)",
    qualification: "MASTER MARINER (FC)",
  },
  {
    name: "MR MURALEEDHARA N PILLAI (MPP)",
    qualification: "B.A",
  },
  {
    name: "CAPT P K THOMAS (PKT)",
    qualification: "MASTER MARINER (FG)",
  },
  {
    name: "MR PRADOSH A C {PRn",
    qualification: "B.TECH,M.TECH",
  },
  {
    name: "CAPT.RAJEEV MENON (RJV)",
    qualification: "MASTER MARINER (NCV)",
  },
  {
    name: "MR SATHEESH G (SGN)",
    qualification: "B.TECH MECHAN ICAL",
  },
  {
    name: "MR PRASANTH R (PRR)",
    qualification: "B.E (MECH) ,M.TECH",
  },
  {
    name: "MS PRIYANKA REGHUTHAMAN(P NK)",
    qualification: "M.TECH",
  },
  {
    name: "MS SERENE p TOM (sPn",
    qualification: "MSC (MATHS)",
  },
  {
    name: "MR SHANKAR MENON (SRM)",
    qualification: "RADIO OFFICER",
  },
  {
    name: "MR SREEVAS SATHIAMMA RAVINDRANATHANPILLAl(SRS)",
    qualification: "ETO",
  },
  {
    name: "C/E SURESH NAROLI (SNI)",
    qualification: "CHIEF OFFICER",
  },
  {
    name: "MR CAPT. SUNIL KUMAR SIVAKUMAR (SKK)",
    qualification: "MASTER (FG)",
  },
  {
    name: "MR THAIPARAMBIL SUBRANMANIAN SANTHOSH (TSS)",
    qualification: "CHIEF OFFICER",
  },
  {
    name: "DR UDAY D NAIR (UDY)",
    qualification: "MBBS",
  },
  {
    name: "MR VINODKUMAR N J (VKN)",
    qualification: "DIPLOMAIN FP",
  },
];

export const data2 = [
  {
    name: "MR ABRAHAM GEORGE (AGG) ",
    qualification: "WELDER",
  },
  {
    name: "MR ALBERT DAMIAN (ALB)",
    qualification: "HON. OFFICER (DECK)",
  },
  {
    name: "MR BINU C (BNC)",
    qualification: "PETT'! OFFICER(DECK)",
  },
  {
    name: "MR B SUBHRAMANYA KURUP (BSK)",
    qualification: "ITI",
  },
  {
    name: "MR CHANORAN PILLAI P (PCP)",
    qualification: "CHIEF PETTY OFFICER(DECKJ",
  },
  {
    name: "MR C R JOHNSON (CRJ)",
    qualification: "NTC 2,-MARINE MECHANIC LEVEL 2 NTC 3- MACHINIST LEVEL 3",
  },
  {
    name: "MRISAAC ABRAHAM (ISC)",
    qualification: "ITI FITTER",
  },
  {
    name: "MR JEEVAN PRASAD (JPR)",
    qualification: "PETTY OFFICER(DECK)",
  },
  {
    name: "JERRNE WILSON (JER)",
    qualification: "BSC NURSING",
  },
  {
    name: "MR JOSE JOSEPH (JJP)",
    qualification: "DIPLOMA IN HOTEL MGT",
  },
  {
    name: "MR KOIPURATHU CHERIYAN THOMAS (KCT)",
    qualification: "CHIEF PETTY OFFICER(DECK)",
  },
  {
    name: "MR MELBIN XAVIER {MEL)",
    qualification: "PETTY OFFICER(ENGG)",
  },
  {
    name: "MR NANDAKUMARAN C N (CNN)",
    qualification: "PETTY OFFICER(DECK)",
  },
  {
    name: "MS REMIVARGHESE (RMI)",
    qualification: "CHIEF PETTY OFFICER(DECK)",
  },
  {
    name: "MR R SUNIL KUMAR (RSK)",
    qualification: "ITI / ITC",
  },
  {
    name: "MR SAJIMON K L (SMK)",
    qualification: "ELECTRICAL OFFICER",
  },
  {
    name: "MR SIMON VARGHESE (SMO)",
    qualification: "ITI MACHINIST",
  },
  {
    name: "MR SUNNY K L",
    qualification: "MASTER CHIEF PETTY OFFICER",
  },
  {
    name: "MR T P BENNY (BNY)",
    qualification: "MASTER CHIEF PETTY OFFICER (ERA)",
  },
  {
    name: "MR T M ANTONY (TMA)",
    qualification: "PETTY OFFICER(DECK)",
  },
  {
    name: "MR VINODU T M (VND)",
    qualification: "PETTY OFFICER(DECK)",
  },
];

export const data3 = [
  {
    name: "MR ELDHO VARGHESE (ELD)",
    qualification: "DIPLOMA IN HOTEL MANAGEMENT",
  },
  {
    name: "MRS.GREESHMA DAVID (GMO)",
    qualification: "MCA",
  },
  {
    name: "CAPT HARINAIR {HNR)",
    qualification: "MASTER MARINER",
  },
  {
    name: "MR JAYESH S (JAY)",
    qualification: "MTECH",
  },
  {
    name: "CAPT.JOBIN WILFRED (JOW)",
    qualification: "MASTER MARINER (FG)",
  },
  {
    name: "MR JEEVAN PRASAD {JPR)",
    qualification: "MASTER MARINER(FG)",
  },
  {
    name: "MR JOSEPH ROJER (ROJ)",
    qualification: "BTECH",
  },
  {
    name: "MR JOSE JOSEPH (JJP)",
    qualification: "DIPLOMA IN HOTEL MGT",
  },
  {
    name: "MR KOIPURATHU CHERIYAN THOMAS {KCT)",
    qualification: "CHIEF PETTY OFFICER(DECK)",
  },
  {
    name: "MR MELBIN XAVIER (MEL)",
    qualification: "PETTY  OFFICER(ENGG)",
  },
  {
    name: "MR NANDAKUMARAN C N (CNN)",
    qualification: "PETTY OFFICER(DECK)",
  },
  {
    name: "MS REMI VARGHESE (RMI)",
    qualification: "CHIEF PETTY OFFICER(DECK)",
  },
  {
    name: "MR R SUNIL KUMAR {RSK)",
    qualification: "ITI/ ITC",
  },
  {
    name: "MR SAJIMON K L (SMK)",
    qualification: "ELECTRICAL OFFICER",
  },
  {
    name: "MR SIMON VARGHESE (SMO)",
    qualification: "ITI MACHINIST",
  },
  {
    name: "MR SUNNY K L",
    qualification: "MASTER CHIEF PETTY OFFICER",
  },
  {
    name: "MR T P BENNY (BNY)",
    qualification: "MASTER CHIEF PETTY OFFICER {ERA)",
  },
  {
    name: "MR T M ANTONY (TMA)",
    qualification: "PETTY OFFICER(DECK)",
  },
  {
    name: "MR VINODU T M (VND)",
    qualification: "PETTY OFFICER(DECK)",
  },
];

// NEW LIST
export const fullTimePermenantFaculty = [
  {
    "Sl. No.": "1",
    name: "CAPT. MATHAI DILEEP KAKKATTUKUZHY",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "2",
    name: "Mr.GIRISH CHANDRA RAMACHANDRAN",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "3",
    name: "C/E JOHNS KURIAN",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "4",
    name: "	Mr. MURALEEDHARA NARAYANA KURUP",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "5",
    name: "	Mr. MURALEEDHARAN PRABHAKARAN PILLAI",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "6",
    name: "	Mr. PILAI AJIT NARAYANAN NARAYANAN PILLAI",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "7",
    name: "Ms. PRIYANKA RAGHUTHAMAN SUDHA",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "8",
    name: "	CAPT. RAJEEV MENON",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "9",
    name: "	Mr. SATHEESH G",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "10",
    name: "CAPT. SUNIL KUMAR KRISHNAKUMAR",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "11",
    name: "	C/E JOB GEORGE",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "12",
    name: "C/E SURESH NAROLI",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "13",
    name: "CAPT. SREEJITH VASAN PUZHIYIL SRINIVASAN",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "14",
    name: "CAPT. ALEX MATHEW",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "15",
    name: "DR.JOBIN THOMAS",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "16",
    name: "	DR.SMITA SMITH",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "17",
    name: "Ms. MEERA PANICKER",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "18",
    name: "CAPT. MADHUSUDAN RAMAKRISHNA MENON",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "19",
    name: "Mr. AYYAPPAN KUTTY KOLLAMPARAMBIL MURALEEDHARAN NAIR	",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "20",
    name: "	CAPT. PRASAD GOVINDRAPURAM",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "21",
    name: "Mr. JOSHY PADAMATHUMMEL VARGHESE",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "22",
    name: "	C/E RAJESH KUMAR MANOVIHAR",
    designation: "FACULTY ",
  },
  {
    "Sl. No.": "23",
    name: "	CAPT. JOHN EPHRAIM",
    designation: "FACULTY ",
  },
];

export const permenantInstructor = [
  {
    "Sl. No.": "1",
    name: "Mr. B SUBHRAMANYA KURUP",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "2",
    name: "Mr. ABRAHAM GEORGE",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "3",
    name: "Mr. ALBERT DAMIAN PALLICHAMPARAMBIL VARGHESE",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "4",
    name: "Mr. BINU CHEMBUNKKARA",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "5",
    name: "	Mr. C R JOHNSON",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "6",
    name: "	Mr. CHANDRAN PILLAI PRABHAKARA KURUP",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "7",
    name: "	Mr. ISAAC ABRAHAM",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "8",
    name: "	Mr. JEEVAN PRASAD",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "9",
    name: "Mr. JOSE JOSEPH",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "10",
    name: "Mr. MATHEW ANTONY THALIATH",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "11",
    name: "Mr. MELBIN XAVIER",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "12",
    name: "	Mr. NIKHIL TOMY",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "13",
    name: "	Ms. REMI VARGHESE",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "14",
    name: "Mr. SAJIMON KARUKAPARAMBIL LEON",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "15",
    name: "Mr. SIMON VARGHESE V",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "16",
    name: "Mr. SUNIL KUMAR RAMACHANDRAN NAIR",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "17",
    name: "	Mr. SUNNY KIDARAKUZHIYIL LUKA",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "18",
    name: "Mr. SURESH BABU KOIMAPARAMBIL KARUNAKARAN",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "19",
    name: "Mr. T P BENNY",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "20",
    name: "Mr. THANDKKAD AYYAPPANPILLAI RADHAKRISHNAN",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "21",
    name: "Mr. SATHYAN K G",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "22",
    name: "Mr. RAJESH GUPTA",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "23",
    name: "Mr. KAVEN FERNADEZ",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "24",
    name: "	Mr. RAJESH NARAYANAN NAIR",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "25",
    name: "	Mr. SAJUMON K JOSEPH",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "26",
    name: "	Mr. CHRISTOPHER SAMSON JOSEPH",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "27",
    name: "Mr. THIRUNAVUKKARASU SETHURAM",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "28",
    name: "	Mr. HARIKUMAR SANTHAKUMARI VIJAYANATHAN",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "29",
    name: "Mr. VIJOY VARGHESE",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "30",
    name: "Mr. JAYACHANDRAN D NAIR",
    designation: "INSTRUCTOR",
  },
  {
    "Sl. No.": "31",
    name: "	Mr. MIDHUN RADHAKRISHNAN",
    designation: "INSTRUCTOR",
  },

];
