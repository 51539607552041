import React from "react";

const ChatBotEuro = ({ name }) => {
  return (
    <div className="fixed bottom-0 sm:right-0 sm:left-auto sm:transform-none sm:translate-x-0 left-3/4 transform -translate-x-1/2 z-50 bg-transparent p-4">
      <a href="tel:+91 7025045000" target="_blank" rel="noreferrer">
        <img
          src="/assets/pngwing.com.png"
          alt="call-to-action"
          className="w-[60px] rounded-full cursor-pointer"
        />
      </a>
    </div>
  );
};

export default ChatBotEuro;
