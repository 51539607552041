import React, { useEffect, useState } from "react";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";

const PackageCourseItem = ({ data, batches }) => {
  const [batchData, setBatchData] = useState([]);

  const fetchBatches = async () => {
    try {
      const res = await axios.get(`${Endpoints.BASE_URL}/batches/${batches}`);
      setBatchData(res.data.resultMessage.data || []);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid date";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  return (
    <div>
      <div className="bg-white  rounded-lg">
        {
          <div className="space-y-4">
            <div className="p-4 border-gray-300   flex justify-between items-center rounded-lg">
              <div className="flex">
                <div>
                  <h3 className="text-lg max-sm:text-base font-medium">
                    {data?.title}
                  </h3>
                  <div className="flex flex-col text-[14px] my-2 mt-0 gap-1">
                    <p>
                      <span className="font-bold">Duration: - </span>
                      <span>{data?.duration}</span>
                    </p>
                    <p>
                      <span className="font-bold">Batch Start Date: - </span>
                      <span className="text-[12px] md:text-[16px]">
                        {formatDate(batchData[0]?.start_date)} and
                      </span>
                      <span className=""> ends on </span>
                      <span className="text-[12px] md:text-[16px]">
                        {formatDate(batchData[0]?.end_date)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PackageCourseItem;
