export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const SET_TIMESTAMP = "SET_TIMESTAMP";

export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";

export const CLEAR_REDUX_INITIAL = "CLEAR_REDUX_INITIAL";

export const SET_CART_COUNT = "SET_CART_COUNT";

export const INCREASE_CART_COUNT = "INCREASE_CART_COUNT";
export const DECREASE_CART_COUNT = "DECREASE_CART_COUNT";
