import React, { useEffect, useState } from "react";
import { EN } from "../locale/EN";
import PageBanner from "../Components/PageBanner";
import { routes } from "../constants/routes";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";

const AntiRagging = () => {
  const [data, setData] = useState(null);

  const CommitteeTable = ({ committee }) => {
    const committeeData = JSON.parse(committee);

    return (
      <table className="min-w-full divide-y divide-gray-200 mt-10 mb-10">
        <thead className="bg-gray-50 hidden sm:table-header-group">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Position
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {committeeData.map((member, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                {member[0]}
                <div className="block sm:hidden text-gray-500 text-xs">
                  {member[1]}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                {member[1]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/anti-ragging-policy`);
        setData(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Explore Euro Tech Maritime Academy | Anti Ragging Policy</title>
        <meta
          name="description"
          content="Eurotech Maritime Academy's Anti Ragging Policy and Reporting"
        />
        <link
          rel="canonical"
          href={`${Endpoints.BASE_URL}` + routes.ANTI_RAGGING}
        />
      </Helmet>
      <div>
        <PageBanner
          imgUrl="/assets/about-banner.png"
          title={EN.anti_ragging.PAGE_TITLE}
        />
      </div>
      <main className="p-16 sm:p-16">
        <h1 className="mt-10 text-2xl font-bold text-blue-900 my-4">
          {data.title}
        </h1>
        <h1 className="font-bold">
          Anti Ragging Committee of Euro Tech Maritime Academy
        </h1>
        <CommitteeTable committee={data.committee} />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div>
        <footer
          className="footer-content mt-10 font-bold text-red-500 text-xl text-left"
          dangerouslySetInnerHTML={{ __html: data.footer_anti_ragging }}
        ></footer>
      </main>
    </>
  );
};

export default AntiRagging;
