import React, { useEffect, useState } from "react";
import { EN } from "../locale/EN";
import PageBanner from "../Components/PageBanner";
import { routes } from "../constants/routes";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";

const Admission = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/admission-list`);
        setData(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Explore Euro Tech Maritime Academy | Admission list</title>
        <meta
          name="description"
          content="Eurotech Maritime Academy's Admission list"
        />
        <link
          rel="canonical"
          href={`${Endpoints.BASE_URL}` + routes.ADMISSION_LIST}
        />
      </Helmet>
      <div>
        <PageBanner
          imgUrl="/assets/about-banner.png"
          title={EN.admission_list.PAGE_TITLE}
        />
      </div>
      <main className="px-16 sm:p-16">
        <h1 className="text-2xl font-bold text-blue-900 my-4">{data.title}</h1>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.admission_list }}
        ></div>
      </main>
    </>
  );
};

export default Admission;
