import React from "react";

const PriceDetailsBox = ({ totalAmount, discount }) => {
  return (
    <div className="mt-6  w-60  pt-4">
                <div className="flex border-b border-[#878787] py-2 justify-between items-center">
                    <span className="text-lg max-sm:text-base font-medium">SUB TOTAL</span>
                    <span className="text-lg max-sm:text-base font-bold">{totalAmount}</span>
                </div>

                <div className="flex border-b border-[#878787] py-2 justify-between items-center mt-2">
                    <span className="text-lg  max-sm:text-base font-medium">TOTAL</span>
                    <span className="text-lg  max-sm:text-base font-bold">₹ {totalAmount}</span>
                </div>
                <div className=' border-b border-[#878787] mt-1'></div>
            </div>
  );
};

export default PriceDetailsBox;
