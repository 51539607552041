import React, { useEffect, useState } from "react";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import PageBanner from "../Components/PageBanner";
import { routes } from "../constants/routes";
import { Helmet } from "react-helmet-async";
import { EN } from "../locale/EN";

const TNC = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/tnc`);
        console.log(res.data);
        setData(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Explore Euro Tech Maritime Academy | Terms and conditions</title>
        <meta
          name="description"
          content="Eurotech Maritime Academy's Terms and conditions"
        />
        <link rel="canonical" href={`${Endpoints.BASE_URL}` + routes.TNC} />
      </Helmet>
      <div>
        <PageBanner
          imgUrl="/assets/Governing-board-banner.png"
          title={EN.footer.TERMS_CONDITIONS}
        />
      </div>
      <main className="p-16 sm:p-16">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data?.tnc }}
        ></div>
      </main>
    </>
  );
};

export default TNC;
