import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { routes } from "../constants/routes";
import { useNavigate } from "react-router-dom";

function BookNowPopup({ isOpen, onClose,selectedCourse  }) {
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(); // Call onClose function to close the popup
  };
 
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center  z-50">
          <div className="relative flex  justify-between bg-white p-6 rounded-lg max-sm:w-full max-lg:w-[70%] w-[45%]">
            <div className="w-32 max-md:hidden h-32">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJ4AAACUCAMAAABVwGAvAAAAh1BMVEX///8AV8xXjP+Gqf8AVctbj/+Apf+xxv8yZ9UvbOBPiP9Uiv91nv+1yf+KrP+Dp/8AUMhLhf/r8P/2+P/w9P98ov8YX9NBgP++0P+lvv/k6//M2f+Ytf+fuv/d5v/S3v9nlv8+eOpynPZaiupLfOE5cdxUg+UAS8Zqk/BLg/SkvPcARcUjauWDCNVaAAAEgklEQVR4nO2baZOqOhCGISxqgAQGQdzBbcR7/v/vuwHUg4gb3VipU3k+T8089Xa6aZbRNIVCoVAoFAqFQqFQKBSKf5Xxap4k3uAlXjJf/3zZ7SdZMIPbb2KwxWn2Pbf5wo248Qmcu4a3+oZcOFjYn7mdsY1p70UOk8ju4lZl6C7HvdrNllFnuTJBlvRol3Qraz1Ae9pXgKHnAuXKABc9NfGy+6mrw1kffuECduxqfnyNrzfFya7yQ58wJ6zsSj+G7DfHy67AnqLazRiqnWFEqPOPPQmPCd5Suvk5G7F91w8PnviLm+1uvx++Zr/bbvjVkOOVd/wgHcY226H/65tvIn50uDvy6rdFaNOlvS+Ysd2bvv4Zpu8PD0YhyBmSXWi0XGmFXCwC+dCuNDT1XSEYIe1/bSePHYefBlfD1w+cYZ2+5V14zNjpXYKrRTjcMJxr7/3MY5sYEN3Zzz/Ycwy9eTM8doxh0VX4e5TqDhp9y7a/CHKFX4ywmYYL3rDr1K9t/NohWG/crCz42F0x/zCw3+xmrLANmpzQGwULqF5j6qF0xYXYCqCbS1LXY3u80hZ6xCHAa0ddjx0xsyv0CE1hel5Njw9x9XRCyARW3poe26KWtkyPkBR031HXw5U76wUDiN486i28So8QyPCrDRbskycGC4Gevp+LHjsiy4mtqtRzXIDe9aLGDti1NbNKzwLMvutKwNBra+alHqGQ6nrnhYoj7VE1PadqDQq58p7XUbZB16s6ozh8gMXvfJfLdti19bOLHoVM5upWiO2x9fScnJlA9oKV28/UG1lXPdB1l/FyHUC2u/Qt/LpW9C6PkfXiqx0JQPds5ehD18trekuInrZ20fXMESFYesU7A1w982/bgosr9gLRHaitYWZWXc+D6WlrG3fu1UsrBgv4aUvCd4gLS0xu9eDPqgYHvGuuSawbPQvhNcfpPyy7OL+1oxH8WYsWwh/qlZh6w45Q4FypWP5BsYudhh2hOM8haQZ8ZKs3xjHi0RPDLyU5cDabetaMToRnYNhp2pQSksXdnz6aYtzd28G2vRqziSgEKSrc6X2GPsrv3UR4kPvIG1hQnBQrG338xsXU4+yuJTAbo2BFq6Ns5ZXhuwi3vK2sBY6LMPTOnOil2YRinmWj12RZ7pBHbognryBMndo8eJeHaoIA681fyTpwnv2xj3FgT/fu8AJUvQD7szSG6TeB7qH3cDw/ivtBRkmI5jdB2VTuiHD8oDdAj0DJzwG/EHrs50L9nCDt4TuvK5y+VngCdfo5dhdCwPlznInbZ3QlUcf8HEpwPiJ4Tmh/np/jBJN02ntyFU/6g7biWO5phbc+vSB82B+p10Ky/t6H6ZVf2p4f6DUPJnZrftLojVvnizR6oj9a8pNIL2ypr0R6bf0hk15Lf8ilN27OZ7n07vpDNr3GfJFNT9NSKrXezXyRT+9mvkioV+8PKfX+zhcp9cT9G5VZ7zpfJNUT/UFl1hP3l1RmPW1czBd59cr+kFhPzJcJ8HPQfgnXX/k/YYVCoVAoFAqFQqH4p/kfHB9k6FCkIicAAAAASUVORK5CYII=" alt="" />
            </div>
            <div>
            <h2 className="text-base text-[#707070] font-semibold mb-2">
              You have selected
            </h2>
            {
              selectedCourse && selectedCourse.length>0 && selectedCourse.map((course) => (
                <p className="mb-2 font-semibold border-b pb-2 text-blue-700 text-[13px]">
                  {course?.title}
                </p>
              ))
            }
            
            {/* <p className="mb-2 font-semibold border-b pb-2 text-blue-700 text-[13px]">
              Pre-Sea Training for Electro-Technical Officer (ETO) Course for
              12th June 2024
            </p> */}
            <p className="text-base mt-2 text-[#707070] pt-2 font-semibold mb-2">
              Do you want to select any additional courses?
            </p>
            <div className="flex justify-start mt-4 gap-8">
              <button
                className="bg-blue-500 max-lg:w-[20%] text-white px-14  max-lg:text-[16px] max-sm:px-0 rounded-lg w-full max-w-[200px] mr-2 max-sm:mr-0"
                onClick={handleClose}
              >
                Yes
              </button>
              <button
                className="bg-blue-700 text-white px-2 py-2 max-sm:py-2 max-lg:text-[16px] max-sm:text-[14px] rounded-lg max-sm:w-[60%] w-[70%] "
                onClick={() => navigate('/course-booking/details')}
              >
                Continue Booking
              </button>
              <div className="absolute top-0 right-[12px] cursor-pointer " onClick={handleClose}>
              <IoCloseCircleOutline style={{width:"20px" , height:"40px"}} />
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BookNowPopup;
